// Roboto Medium
@font-face {
  font-family: 'Roboto';
  src: url('../../../assets/fonts/roboto/roboto-medium-webfont.eot');
  src: url('../../../assets/fonts/roboto/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/roboto/roboto-medium-webfont.woff2') format('woff2'),
        url('../../../assets/fonts/roboto/roboto-medium-webfont.woff') format('woff'),
        url('../../../assets/fonts/roboto/roboto-medium-webfont.svg#robotomedium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// Roboto Regular
@font-face {
  font-family: 'Roboto';
  src: url('../../../assets/fonts/roboto/roboto-regular-webfont.eot');
  src: url('../../../assets/fonts/roboto/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/roboto/roboto-regular-webfont.woff2') format('woff2'),
        url('../../../assets/fonts/roboto/roboto-regular-webfont.woff') format('woff'),
        url('../../../assets/fonts/roboto/roboto-regular-webfont.svg#robotoregular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Roboto Thin
@font-face {
  font-family: 'Roboto';
  src: url('../../../assets/fonts/roboto/roboto-thin-webfont.eot');
  src: url('../../../assets/fonts/roboto/roboto-thin-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/roboto/roboto-thin-webfont.woff2') format('woff2'),
        url('../../../assets/fonts/roboto/roboto-thin-webfont.woff') format('woff'),
        url('../../../assets/fonts/roboto/roboto-thin-webfont.svg#robotothin') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// Amatic SC Bold
@font-face {
  font-family: 'Amatic SC';
  src: url('../../../assets/fonts/amatic-sc/amaticsc-bold-webfont.eot');
  src: url('../../../assets/fonts/amatic-sc/amaticsc-bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/amatic-sc/amaticsc-bold-webfont.woff2') format('woff2'),
        url('../../../assets/fonts/amatic-sc/amaticsc-bold-webfont.woff') format('woff'),
        url('../../../assets/fonts/amatic-sc/amaticsc-bold-webfont.svg#amatic_scbold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
