.sub-header, .section-header {
  font-family: "Amatic SC", sans-serif;
  font-weight: 700;
}

.footer__bottom:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(58, 58, 58, 0.8);
  z-index: 1;
}

.join-us:after, .join-us:before {
  content: "";
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  height: 10px;
  background-size: 443px 10px;
  background-repeat: repeat-x;
}

.story, .footer {
  margin-top: 14vh;
}

.story > *:not(.story__header) {
  margin-bottom: 7vh;
}

.footer__bottom {
  background-color: rgba(58, 58, 58, 0.8);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.footer__bottom {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button {
  cursor: pointer;
  font-family: "Roboto", Segoe UI, sans-serif;
  -webkit-appearance: none;
  transition: all 0.3s;
  text-align: center;
  font-size: 1rem;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/roboto/roboto-medium-webfont.eot");
  src: url("../../../assets/fonts/roboto/roboto-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../../../assets/fonts/roboto/roboto-medium-webfont.woff2") format("woff2"), url("../../../assets/fonts/roboto/roboto-medium-webfont.woff") format("woff"), url("../../../assets/fonts/roboto/roboto-medium-webfont.svg#robotomedium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/roboto/roboto-regular-webfont.eot");
  src: url("../../../assets/fonts/roboto/roboto-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../../assets/fonts/roboto/roboto-regular-webfont.woff2") format("woff2"), url("../../../assets/fonts/roboto/roboto-regular-webfont.woff") format("woff"), url("../../../assets/fonts/roboto/roboto-regular-webfont.svg#robotoregular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../assets/fonts/roboto/roboto-thin-webfont.eot");
  src: url("../../../assets/fonts/roboto/roboto-thin-webfont.eot?#iefix") format("embedded-opentype"), url("../../../assets/fonts/roboto/roboto-thin-webfont.woff2") format("woff2"), url("../../../assets/fonts/roboto/roboto-thin-webfont.woff") format("woff"), url("../../../assets/fonts/roboto/roboto-thin-webfont.svg#robotothin") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Amatic SC";
  src: url("../../../assets/fonts/amatic-sc/amaticsc-bold-webfont.eot");
  src: url("../../../assets/fonts/amatic-sc/amaticsc-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../../../assets/fonts/amatic-sc/amaticsc-bold-webfont.woff2") format("woff2"), url("../../../assets/fonts/amatic-sc/amaticsc-bold-webfont.woff") format("woff"), url("../../../assets/fonts/amatic-sc/amaticsc-bold-webfont.svg#amatic_scbold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
.sub-header, .section-header {
  font-family: "Amatic SC", sans-serif;
  font-weight: 700;
}

.footer__bottom:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(58, 58, 58, 0.8);
  z-index: 1;
}

.join-us:after, .join-us:before {
  content: "";
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  height: 10px;
  background-size: 443px 10px;
  background-repeat: repeat-x;
}

.story, .footer {
  margin-top: 14vh;
}

.story > *:not(.story__header) {
  margin-bottom: 7vh;
}

.footer__bottom {
  background-color: rgba(58, 58, 58, 0.8);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.footer__bottom {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button {
  cursor: pointer;
  font-family: "Roboto", Segoe UI, sans-serif;
  -webkit-appearance: none;
  transition: all 0.3s;
  text-align: center;
  font-size: 1rem;
}

/**
 * 1. Avoid the IE 10-11 `min-height` bug.
 * 2. Set `flex-shrink` to `0` to prevent some browsers from
 *    letting these items shrink to smaller than their content's default
 *    minimum size. See http://bit.ly/1Mn35US for details.
 * 3. Use `%` instead of `vh` since `vh` is buggy in older mobile Safari.
 */
body {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* 1, 3 */
}

body > .header,
body > .footer {
  flex: none;
  /* 2 */
}

body > .main {
  flex: 1 0 auto;
  /* 2 */
}

.map-page .footer {
  margin-top: 0;
}
.footer .footer__bottom-menu-item, .footer .footer__bottom-social-media-item {
  margin-bottom: 2.8vh;
}
.footer .footer__bottom-menu-item:last-of-type, .footer .footer__bottom-social-media-item:last-of-type {
  margin-bottom: 0;
}
@media screen and (min-width: 64em) {
  .footer .footer__bottom-menu-item, .footer .footer__bottom-social-media-item {
    margin-bottom: 0;
    padding: 0 10px;
  }
}
.footer .footer__bottom-menu-label, .footer .footer__bottom-social-media-label {
  font-size: 0.9rem;
}
@media screen and (min-width: 48em) {
  .footer .footer__bottom-menu-label, .footer .footer__bottom-social-media-label {
    font-size: 1rem;
  }
}
.footer__bottom {
  min-height: 240px;
  color: #fefefe;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 30px;
  overflow: hidden;
}
@media screen and (min-width: 48em) {
  .footer__bottom {
    min-height: 320px;
    padding-top: 80px;
  }
}
@media screen and (min-width: 64em) {
  .footer__bottom {
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
.footer__bottom > * {
  z-index: 3;
}
.footer__bottom-title {
  margin-bottom: 4vh;
  font-size: 1.2rem;
}
@media screen and (min-width: 64em) {
  .footer__bottom-title {
    margin-bottom: 0;
  }
}
.footer__bottom-social-media {
  margin-bottom: 5vh;
}
@media screen and (min-width: 64em) {
  .footer__bottom-social-media {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    height: 34px;
  }
}
.footer__bottom-social-media-item-link {
  color: #fefefe;
  text-decoration: none;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
}
.footer__bottom-social-media-item-link:hover, .footer__bottom-social-media-item-link:focus {
  text-decoration: none;
}
.footer__bottom-social-media-social-media-label, .footer__bottom-social-media-icon {
  display: inline-block;
  vertical-align: middle;
}
.footer__bottom-social-media-icon {
  width: 26px;
  height: 26px;
  margin-right: 10px;
  flex: 1;
  flex-basis: 26px;
  background: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__bottom-social-media-icon > img {
  width: 16px;
  height: 16px;
}
@media screen and (min-width: 64em) {
  .footer__bottom-social-media-icon {
    width: 44px;
    height: 44px;
    flex-basis: 44px;
  }
  .footer__bottom-social-media-icon > img {
    width: 34px;
    height: 34px;
  }
}
@media screen and (min-width: 64em) and (max-width: 1280px) {
  .footer__bottom-social-media-label {
    display: none;
  }
}
@media screen and (min-width: 64em) {
  .footer__bottom-menu {
    display: flex;
    height: 34px;
  }
}
@media screen and (min-width: 48em) {
  .footer__bottom-menu-item {
    display: inline-flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.footer__bottom-menu-label {
  padding: 0;
  background: transparent;
  border: 0;
  color: #fefefe;
  font-family: "Roboto", Segoe UI, sans-serif;
  cursor: pointer;
  text-align: left;
}

.sub-header, .section-header {
  font-family: "Amatic SC", sans-serif;
  font-weight: 700;
}

.footer__bottom:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(58, 58, 58, 0.8);
  z-index: 1;
}

.join-us:after, .join-us:before {
  content: "";
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  height: 10px;
  background-size: 443px 10px;
  background-repeat: repeat-x;
}

.story, .footer {
  margin-top: 14vh;
}

.story > *:not(.story__header) {
  margin-bottom: 7vh;
}

.footer__bottom {
  background-color: rgba(58, 58, 58, 0.8);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.footer__bottom {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button {
  cursor: pointer;
  font-family: "Roboto", Segoe UI, sans-serif;
  -webkit-appearance: none;
  transition: all 0.3s;
  text-align: center;
  font-size: 1rem;
}

.join-us {
  background: white;
  padding-top: 30px;
  padding-bottom: 30px;
}
.join-us:before {
  background-image: url(../../hero/grounge-mask-hero@2x.png);
  top: -6px;
}
.map-page .join-us {
  background: #efe9dc;
}
.map-page .join-us:before {
  display: none;
}
.map-page .join-us:after {
  background-image: url(../../hero/grounge-mask-hero@2x.png);
  bottom: -2px;
}
.join-us:after {
  bottom: -6px;
  background-image: url(grounge-mask-footer-white@2x.png);
}
.join-us__text {
  max-width: 500px;
  margin-left: var(--gutter);
  margin-right: var(--gutter);
  margin-bottom: 3vh;
}
@media screen and (min-width: 33.75em) {
  .join-us__text {
    margin-left: auto;
    margin-right: auto;
  }
}

.newsletter-form {
  max-width: 500px;
  margin-left: var(--gutter);
  margin-right: var(--gutter);
}
@media screen and (min-width: 33.75em) {
  .newsletter-form {
    margin-left: auto;
    margin-right: auto;
  }
}
.newsletter-form .form__row:last-of-type {
  justify-content: center;
}

.sub-header, .section-header {
  font-family: "Amatic SC", sans-serif;
  font-weight: 700;
}

.footer__bottom:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(58, 58, 58, 0.8);
  z-index: 1;
}

.join-us:before, .join-us:after {
  content: "";
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  height: 10px;
  background-size: 443px 10px;
  background-repeat: repeat-x;
}

.story, .footer {
  margin-top: 14vh;
}

.story > *:not(.story__header) {
  margin-bottom: 7vh;
}

.footer__bottom {
  background-color: rgba(58, 58, 58, 0.8);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.footer__bottom {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button {
  cursor: pointer;
  font-family: "Roboto", Segoe UI, sans-serif;
  -webkit-appearance: none;
  transition: all 0.3s;
  text-align: center;
  font-size: 1rem;
}

.button {
  background: #22943d;
  border-radius: 4px;
  display: inline-block;
  color: #fefefe;
  text-decoration: none;
  padding: 1em 30px;
  font-weight: 500;
  letter-spacing: 1px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #449f5a;
  border-bottom: 1px solid #154d22;
  border-right: 1px solid #154d22;
  border-left: 1px solid #449f5a;
}
.button:hover:not([disabled]), .button:focus:not([disabled]) {
  text-decoration: none;
  border-top-color: #154d22;
  border-bottom-color: #449f5a;
  border-right-color: #449f5a;
  border-left-color: #154d22;
}
.button--orange {
  background: #dd8f14;
  border-top: 1px solid #eda637;
  border-bottom: 1px solid #ae7110;
  border-right: 1px solid #ae7110;
  border-left: 1px solid #eda637;
}
.button--orange:hover:not([disabled]), .button--orange:focus:not([disabled]) {
  text-decoration: none;
  border-top-color: #ae7110;
  border-bottom-color: #eda637;
  border-right-color: #eda637;
  border-left-color: #ae7110;
}
.button--small {
  font-size: 0.8125rem;
  padding: 0.5em 15px;
}
.button--outline {
  background: transparent;
  border: 1px solid #22943d;
}
.button--outline:hover:not([disabled]), .button--outline:focus:not([disabled]) {
  border-color: #186f2d;
}
.button--cta {
  font-size: 1.125rem;
  padding-right: 40px;
  padding-left: 40px;
}
.button--fb {
  padding-right: 75px;
  background-color: #1877f2;
  border-top: 1px solid #79b0f7;
  border-bottom: 1px solid #09499b;
  border-right: 1px solid #09499b;
  border-left: 1px solid #79b0f7;
  display: block;
  max-width: 300px;
  margin: 0 10px;
}
@media screen and (min-width: 320px) {
  .button--fb {
    margin: 0 auto;
  }
}
.button--fb:hover:not([disabled]), .button--fb:focus:not([disabled]) {
  border-top: 1px solid #09499b;
  border-bottom: 1px solid #79b0f7;
  border-right: 1px solid #79b0f7;
  border-left: 1px solid #09499b;
}
.button--fb:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 29px;
  background-repeat: no-repeat;
  background-size: 30px 29px;
  top: 50%;
  margin-top: -14.5px;
  right: 30px;
}
.button--fb.lazy-loading-loaded:after {
  background-image: url(../../../assets/images/icons/fb-like@2x.png);
}
.button[disabled] {
  opacity: 0.75;
  cursor: not-allowed;
}
.button--loader .button__spinner {
  display: none;
}
.button--loader[disabled] .button__spinner {
  display: block;
}
.button--loader[disabled] .button__text {
  visibility: hidden;
}

.form label,
.form label > input {
  display: block;
  width: 100%;
}
.form__label {
  display: block;
  margin-bottom: 3px;
  line-height: 1.1;
  font-size: 0.9rem;
  cursor: pointer;
}
.form__row {
  margin-bottom: 10px;
}
.form__row:last-of-type {
  margin-bottom: 0;
}
@media screen and (min-width: 48em) {
  .form__row {
    display: flex;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 48em) {
  .form__row--single-line {
    flex-wrap: nowrap;
  }
}
.form input {
  padding: 12px 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: white;
  color: #515154;
  transition: all 0.3s ease-in-out;
  font-family: "Roboto", Segoe UI, sans-serif;
  font-size: 1rem;
  -webkit-appearance: none;
}
.form input:focus {
  border-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 6px rgba(87, 90, 227, 0.4);
  outline: none;
}
.form__asterisk {
  vertical-align: super;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  color: red;
  line-height: 0;
  user-select: none;
}
.form__asterisk--no-super {
  vertical-align: baseline;
}
.form__legend {
  font-style: italic;
  margin-top: 20px;
  font-size: 0.8rem;
}
@media screen and (min-width: 48em) {
  .form__legend {
    text-align: right;
  }
}

.sub-header, .section-header {
  font-family: "Amatic SC", sans-serif;
  font-weight: 700;
}

.footer__bottom:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(58, 58, 58, 0.8);
  z-index: 1;
}

.join-us:before, .join-us:after {
  content: "";
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  height: 10px;
  background-size: 443px 10px;
  background-repeat: repeat-x;
}

.story, .footer {
  margin-top: 14vh;
}

.story > *:not(.story__header) {
  margin-bottom: 7vh;
}

.footer__bottom {
  background-color: rgba(58, 58, 58, 0.8);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.footer__bottom {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button {
  cursor: pointer;
  font-family: "Roboto", Segoe UI, sans-serif;
  -webkit-appearance: none;
  transition: all 0.3s;
  text-align: center;
  font-size: 1rem;
}

.section-header {
  max-width: 1326px;
  margin-left: var(--gutter);
  margin-right: var(--gutter);
  text-align: center;
  font-size: calc(1.8em + .5vw);
  padding-bottom: 0.625rem;
}
@media screen and (min-width: 85.375em) {
  .section-header {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 48em) {
  .section-header {
    font-size: calc(2.2em + .5vw);
  }
}
@media print {
  .section-header {
    page-break-after: avoid;
    break-after: avoid;
  }
}
.section-header:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -42px;
  width: 84px;
  height: 3px;
  background: url("section-header-hr@2x.png") no-repeat 0 0;
  background-size: 84px 3px;
}
.section-header--footer {
  max-width: none;
  padding-right: var(--gutter);
  margin-bottom: 6vh;
}

.sub-header, .section-header {
  font-family: "Amatic SC", sans-serif;
  font-weight: 700;
}

.footer__bottom:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(58, 58, 58, 0.8);
  z-index: 1;
}

.join-us:before, .join-us:after {
  content: "";
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  height: 10px;
  background-size: 443px 10px;
  background-repeat: repeat-x;
}

.story, .footer {
  margin-top: 14vh;
}

.story > *:not(.story__header) {
  margin-bottom: 7vh;
}

.footer__bottom {
  background-color: rgba(58, 58, 58, 0.8);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.footer__bottom {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button {
  cursor: pointer;
  font-family: "Roboto", Segoe UI, sans-serif;
  -webkit-appearance: none;
  transition: all 0.3s;
  text-align: center;
  font-size: 1rem;
}

.sub-header {
  font-size: calc(1.5em + .5vw);
  letter-spacing: 0.0625rem;
  line-height: 1.2;
}
@media screen and (min-width: 48em) {
  .sub-header {
    font-size: calc(1.8em + .2vw);
  }
}
.sub-header--footer {
  max-width: none;
  padding-right: var(--gutter);
  text-align: center;
  margin-top: 6vh;
  margin-bottom: 3vh;
}

.text-center {
  text-align: center;
}

.anim-underline:after {
  position: absolute;
  display: inline-block;
  content: "";
  left: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  width: 0;
  height: 1px;
  background-color: currentColor;
}
.anim-underline:hover, .anim-underline:focus {
  text-decoration: none;
}
.anim-underline:hover:after, .anim-underline:focus:after {
  width: 100%;
  opacity: 1;
}

/**
Use this as a wrapper for flow content where we don't want to add a class to every element
 */
.main-copy p {
  font-size: calc(1em + .1vw);
  margin-bottom: 1.3em;
  line-height: 1.4;
}
@media print {
  .main-copy p {
    orphans: 2;
    widows: 2;
  }
}
.main-copy strong {
  font-weight: 500;
}
.main-copy a {
  text-decoration: underline;
}
.main-copy a:hover, .main-copy a:focus, .main-copy a:active {
  text-decoration: none;
}
.main-copy em {
  font-style: italic;
}

.story__header {
  margin-bottom: 5vh;
}
.story > *:last-child {
  margin-bottom: 0;
}

/*
Credits: https://codepen.io/MattIn4D/pen/LiKFC
*/
/* Absolute Center Spinner */
.spinner {
  position: absolute;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* :not(:required) hides these rules from IE9 and below */
}
.spinner:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.spinner:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  box-shadow: #fff 1.5em 0 0 0, #fff 1.1em 1.1em 0 0, #fff 0 1.5em 0 0, #fff -1.1em 1.1em 0 0, #fff -1.5em 0 0 0, #fff -1.1em -1.1em 0 0, #fff 0 -1.5em 0 0, #fff 1.1em -1.1em 0 0;
}

/* Animation */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mt-2 {
  margin-top: 2rem;
}

.section-v-gutter {
  margin-bottom: 7vh;
}