@use "../../scss/config/typography" as t;
@use "../../scss/config/sizes" as s;
@use "../../scss/config/spacings" as sp;
@use "../../scss/config/breakpoints" as bp;
@use "../../scss/config/functions" as f;
@use "../../scss/config/mixins" as m;
@import "../../scss/config/silent-classes";

.section-header {
  @include m.pillar-box(s.$page-max-w);
  @extend %header-font;
  text-align: center;
  font-size: calc(#{t.$header-font-size} + .5vw);
  padding-bottom: f.px-to-rem(10);

  @media screen and (min-width: bp.$tablet) {
    font-size: calc(#{t.$header-font-size-desktop} + .5vw);
  }

  @media print {
    page-break-after: avoid;
    break-after: avoid;
  }

  &:after {
    $w: 84px;
    $h: 3px;

    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -($w / 2);
    width: $w;
    height: $h;
    background: url('section-header-hr@2x.png') no-repeat 0 0;
    background-size: $w $h;
  }

  &--footer {
    max-width: none;
    padding-right: var(--gutter);
    margin-bottom: sp.$v-gutter-far;
  }

}
