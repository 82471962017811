@use "../../../../scss/config/breakpoints" as bp;
@use "../../../../scss/config/mixins" as m;
@use "../../../../scss/config/sizes" as s;

.newsletter-form {
  @include m.pillar-box(500px);

  .form__row:last-of-type {
    justify-content: center;
  }

}
