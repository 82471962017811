.story {
  @extend %section-v-divider;

  &__header {
    margin-bottom: 5vh;
  }

  > *:not(.story__header) {
    @extend %v-gutter-inside-section;
  }

  > *:last-child {
    margin-bottom: 0;
  }

}