@use "../config/spacings" as sp;
@use "../config/breakpoints" as bp;
@use "../config/decorations" as d;
@use "../config/colors" as c;
@use "../config/typography" as t;

.form {

  label,
  label > input {
    display: block;
    width: 100%;
  }

  &__label {
    display: block;
    margin-bottom: 3px;
    line-height: 1.1;
    font-size: .9rem;
    cursor: pointer;
  }

  &__row {
    margin-bottom: sp.$gutter;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media screen and (min-width: bp.$tablet) {
      display: flex;
      margin-bottom: sp.$gutter-desktop;
    }

  }

  &__row--single-line {
    @media screen and (min-width: bp.$tablet) {
      flex-wrap: nowrap;
    }
  }

  input {
    padding: 12px sp.$gutter;
    border: 2px solid rgba(0, 0, 0, .2);
    border-radius: d.$form-border-radius;
    background: white;
    color: c.$base-text-color;
    transition: all .3s ease-in-out;
    font-family: t.$copy-font;
    font-size: 1rem;
    -webkit-appearance: none;

    &:focus {
      border-color: rgba(0, 0, 0, .4);
      box-shadow: 0 0 6px rgba(c.$purple, .4);
      outline: none;
    }

  }

  &__asterisk {
    vertical-align: super;
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    color: rgb(255, 0, 0);
    line-height: 0;
    user-select: none;
  }

  &__asterisk--no-super {
    vertical-align: baseline;
  }

  &__legend {
    font-style: italic;
    margin-top: sp.$gutter-desktop;
    font-size: .8rem;

    @media screen and (min-width: bp.$tablet) {
      text-align: right;
    }

  }

}
