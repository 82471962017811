@use "../../scss/config/colors" as c;
@use "../../scss/config/z-indexes" as z;
@use "../../scss/config/breakpoints" as bp;
@use "../../scss/config/functions" as f;
@use "../../scss/config/spacings" as sp;

.cookie-consent {
  display: block;
  position: fixed;
  border: none;
  padding: var(--gutter);
  right: var(--gutter);
  left: auto;
  bottom: var(--gutter);
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  z-index: z.$z-highest;
  border-radius: 10px;
  width: 90%;
  background: c.$base-text-color;
  color: c.$almost-white;
  transition: all .3s ease-in-out;
  opacity: 0;
  transform: translate3d(0, 20%, 0);

  &.cookie-consent--show {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  @media screen and (min-width: bp.$phablet) {
    left: auto;
    width: 70%;
  }

  @media screen and (min-width: bp.$tablet) {
    width: 50%;
  }

  @media screen and (min-width: bp.$desktop) {
    width: f.px-to-rem(400px);
  }

  &__text {
    font-size: f.px-to-rem(13px);
    padding-bottom: var(--gutter);
  }

  &__options {
    display: flex;
    flex-wrap: wrap;
  }

  &__options-item {
    margin: 0 sp.$gutter sp.$gutter 0;
    font-size: f.px-to-rem(13px);
  }

  &__button {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}
