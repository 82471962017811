@use "../../../scss/config/spacings" as sp;
@use "../../../scss/config/breakpoints" as bp;
@use "../../../scss/config/functions" as f;
@use "../../../scss/config/mixins" as m;
@use "../../../scss/config/colors" as c;
@import "../../../scss/config/silent-classes";

.join-us {
  background: white;
  padding-top: sp.$grid-v-gutter;
  padding-bottom: sp.$grid-v-gutter;

  &:before {
    @extend %grounge-mask;
    background-image: url(../../hero/grounge-mask-hero@2x.png);
    top: -6px;
  }

  .map-page & {
    background: c.$beige;

    &:before {
      display: none;
    }

    &:after {
      background-image: url(../../hero/grounge-mask-hero@2x.png);
      bottom: -2px;
    }

  }

  &:after {
    @extend %grounge-mask;
    bottom: -6px;
    background-image: url(grounge-mask-footer-white@2x.png);
  }

  &__text {
    @include m.pillar-box(500px);
    margin-bottom: sp.$v-gutter-near;
  }

}
