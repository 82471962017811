@use "../config/mixins" as m;
@use "../config/colors" as c;
@use "../config/typography" as t;
@use "../config/spacings" as sp;
@use "../config/decorations" as d;
@use "../config/functions" as f;
@import "../config/silent-classes";

$side-padding: 30px;

.button {
  @extend %button-base;
  @extend %single-line-ellipsis;
  background: c.$green;
  border-radius: d.$form-border-radius;
  display: inline-block;
  color: c.$almost-white;
  text-decoration: none;
  padding: 1em $side-padding;
  font-weight: 500;
  letter-spacing: 1px;
  text-shadow: 1px 1px rgba(0, 0, 0, .1);
  border-top: 1px solid c.$light-green-border;
  border-bottom: 1px solid c.$dark-green-border;
  border-right: 1px solid c.$dark-green-border;
  border-left: 1px solid c.$light-green-border;

  &:hover,
  &:focus {
    &:not([disabled]) {
      text-decoration: none;
      border-top-color: c.$dark-green-border;
      border-bottom-color: c.$light-green-border;
      border-right-color: c.$light-green-border;
      border-left-color: c.$dark-green-border;
      ;
    }
  }

  &--orange {
    background: c.$orange;
    border-top: 1px solid lighten(c.$orange, 10%);
    border-bottom: 1px solid darken(c.$orange, 10%);
    border-right: 1px solid darken(c.$orange, 10%);
    border-left: 1px solid lighten(c.$orange, 10%);

    &:hover,
    &:focus {
      &:not([disabled]) {
        text-decoration: none;
        border-top-color: darken(c.$orange, 10%);
        border-bottom-color: lighten(c.$orange, 10%);
        border-right-color: lighten(c.$orange, 10%);
        border-left-color: darken(c.$orange, 10%);
      ;
      }
    }
  }

  &--small {
    font-size: f.px-to-rem(13px);
    padding: .5em $side-padding / 2;
  }

  &--outline {
    background: transparent;
    border: 1px solid c.$green;

    &:hover,
    &:focus {
      &:not([disabled]) {
        border-color: c.$dark-green;
      }
    }
  }

  &--cta {
    font-size: t.$cta-font-size;
    padding-right: 40px;
    padding-left: 40px;
  }

  &--fb {
    $icon-w: 30px;
    $icon-h: 29px;
    $mw: 300px;
    padding-right: $side-padding + $icon-w + $side-padding / 2;
    background-color: c.$facebook-blue;
    border-top: 1px solid lighten(c.$facebook-blue, 20%);
    border-bottom: 1px solid darken(c.$facebook-blue, 20%);
    border-right: 1px solid darken(c.$facebook-blue, 20%);
    border-left: 1px solid lighten(c.$facebook-blue, 20%);
    display: block;
    max-width: $mw;
    margin: 0 sp.$gutter;

    @media screen and  (min-width: $mw + (sp.$gutter * 2)) {
      margin: 0 auto;
    }

    &:hover,
    &:focus {
      &:not([disabled]) {
        border-top: 1px solid darken(c.$facebook-blue, 20%);
        border-bottom: 1px solid lighten(c.$facebook-blue, 20%);
        border-right: 1px solid lighten(c.$facebook-blue, 20%);
        border-left: 1px solid darken(c.$facebook-blue, 20%);
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: $icon-w;
      height: $icon-h;
      background-repeat:  no-repeat;
      background-size: $icon-w $icon-h;
      top: 50%;
      margin-top: -($icon-h / 2);
      right: $side-padding;
    }

    &.lazy-loading-loaded {
      &:after {
        background-image: url(../../../assets/images/icons/fb-like@2x.png);
      }
    }

  }

  &[disabled] {
    opacity: .75;
    cursor: not-allowed;
  }

  &--loader {

    .button__spinner {
      display: none;
    }

    &[disabled] {

      .button__spinner {
        display: block;
      }

      .button__text {
        visibility: hidden;
      }

    }

  }

}
